<template>
  <div v-if="editable" class="editwrapper">
    <Tag v-for="(t,ind) in tags" :key="ind" closable :size="size" @on-close="delTag(ind)">{{t}}</Tag>
    <div style="display:inline-block">
      <Input
        style="min-width:7em;"
        :style="{width:inputWidth+'px'}"
        :class="hasError?'err-input':''"
        :maxlength="40"
        v-model="inputTag"
        placeholder="新标签..."
        @keyup.enter.native="addTag"
        @on-blur="addTag"
        @on-change="changeWidth"
      />
      <div
        style="color:rgb(235,57,65);height:1.1em;font-size:12px;display:none"
        theme="dark"
      >{{hasError?'不能含有特殊字符':' '}}</div>
    </div>
    <!--  -->
  </div>
  <div v-else style="display:inline-block">
    <Tag v-for="(t,ind) in tags" :key="ind" :size="size">{{t}}</Tag>
  </div>
</template>

<script>
const reg = /^[\w\ \u4e00-\u9fa5]*$/;
export default {
  props: {
    tags: {
      required: true,
      type: Array
    },
    editable: {
      default: true
    },
    size: {
      type: String,
      default: "medium"
    }
  },
  data() {
    return {
      inputTag: "",
      inputWidth: 30,
      hasError: false
    };
  },
  methods: {
    addTag(e) {
      //
      // reg.test(this.inputTag.trim())
      if (this.inputTag.trim()) {
        if (this.inputTag.trim()) {
          this.tags.push(this.inputTag.trim());
        }
        this.inputTag = "";
        this.changeWidth(e);
      }
    },
    delTag(ind) {
      this.tags.splice(ind, 1);
    },
    changeWidth(e) {
      // 去掉校验规则：
      // this.hasError = !reg.test(this.inputTag.trim());
      this.hasError = false;
      this.inputWidth = 0;
      this.$nextTick(() => {
        this.inputWidth = e.target.scrollWidth + 4;
      });
    }
  }
};
</script>

<style scoped>
.editwrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.err-input ::v-deep input {
  border-color: rgb(235, 57, 65);
}
.err-input ::v-deep input:focus {
  box-shadow: 0 0 0 1px rgba(235, 57, 65, 0.3);
}
</style>